const TITLE = 'The Fall Of Babel';

const PART1 = "Part I - The Daredevil's Brother";
const PART2 = 'Part II - Steel Bird, Iron Worm';
const PART3 = 'Part III - The Bridge Of Babel';

export const bookFour = {
    title: TITLE,
    parts: [PART1, PART2, PART3],
    chapters: [
        {
            book: TITLE,
            part: PART1,
            chapter: '1',
            quote: 'Courage circulates like a melody sung in a round. It is an infinite canon, an infectious and intoxicating performance.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '2',
            quote: 'Those who claim to be ready for anything are overpacked and invariably unprepared for the one obstacle every adventurer must eventually face—disappointment.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '3',
            quote: 'The birds do not dwell upon the miracle of the air, nor do the fish marvel at the currents that carry them. I suspect such is the case for the lords and ladies of the Tower. For them, gold has become the invisible medium of life.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '4',
            quote: "Never was a line untangled by heaves and tugs. An unwanted knot requires a flexible stratagem to undo. One must give a little here to make some progress there. So it is with most of life's snarls.",
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '5',
            quote: "The crowning ringdom's only known import are the lustful gazes of stupid men. Her primary exports include shrieks and orphans.",
            source: "Everyman's Guide to the Tower of Babel, IX. XVII",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '6',
            quote: 'A lord once told me that wealth is squandered upon the poor, much as rain is wasted upon puddles. But who wants the rain to fall all in one place? Is that not what cleaves the levee, what drowns the valley, what empties the boneyards only to fill them again? More puddles, I say. Fewer floods.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '7',
            quote: 'Some of my crew are convinced an old rope will continue to hold purely because it has held for so long. As if a ship was buoyed by precedent. As if the past promised a future.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '8',
            quote: 'To scrounge funds for this expedition, I haunted the courts of the middle ringdoms. Taking advantage of the romantic feelings the middle class hold for rogues, I presented myself as an adventuresome rascal embarking upon a noble quest. Their purses burst open for me. I robbed them, and they applauded.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '9',
            quote: "The night is haunted. I cannot sleep for thinking of home—home, where my son still rocks in his cradle; home, where my love's hair is still red as a cardinal. Our garden is green. Spring is unending. I grow old in the company of youthful ghosts.",
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '10',
            quote: "It is bad manners to inquire into an airman's past and imprudent to divulge your own. We must behave as if each of us emerged fully formed, not from a womb, but from a sculptor's block.",
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '11',
            quote: "Many of the traps I've fallen for were not furtive but rather signed, sealed, and sent through the post. One can hardly call it an ambush if one RSVPs.",
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '12',
            quote: 'Anger that survives until morning is either righteous or insidious. Either way, it must be dealt with.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '13',
            quote: 'I hope to be rich one day, but not miserably so. I once rubbed elbows with a gold-drenched lord. He had a staff of dozens, a harem of admirers, and a seat at every table. Yet I have never met a more paranoid, anxious, and isolated wretch. The only medicine for gout is moderation; the only cure for excess is charity.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '14',
            quote: "Men who brag about how they would've risen to the occasion amid some unattended crisis are pigeon-livered liars. There is nothing more flattering to one's ego, nor more insulting to a survivor, than armchair courage and the valor of the parlor.",
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '15',
            quote: 'To lead is to come last. A chef only eats when the dishes are done, and a captain goes down with the ship.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '16',
            quote: 'Scavenge is such an unlovely word. It conjures up visions of vultures and grave robbers. We only take what the dead could not carry.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '17',
            quote: 'Any airman who has ever killed another in battle has learned the unhappy truth: Ships sink; ghosts float.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '18',
            quote: 'Retreat is an ancient art. Generals have studied it; conscripts practiced it. Nearly fifty years of survival has taught me that often the best time to run away is shortly before you arrive.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '19',
            quote: 'Trust no man with a secret unless its preservation will enrich himself as well. The soul of discretion is not integrity but stock.',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '20',
            quote: 'I have done it. I have scrabbled into heaven… All the Tower under the Collar now seems little better than a poorhouse. ',
            source: 'from the diary of Joram Brahe, captain of the Natchez King',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: 'From the Belly of the Beast 1',
            quote: 'We have spent our lives scrabbling up the mountainside without the promise of a mountaintop.',
            source: 'from the sermons of Luc Marat (translated from hoddish)',
        },

        {
            book: TITLE,
            part: PART2,
            chapter: '1',
            quote: 'Choose your traveling companions wisely. The Tower has a way of turning quibbles into feuds and a morning spat into an evening divorce.',
            source: "Everyman's Guide to the Tower of Babel, III. IV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '2',
            quote: "Much as a meandering river will pinch off an inconvenient bend, the Tower affords visitors the opportunity to cut away the errant twists and embarrassing turns of the lives they've left behind.",
            source: "Everyman's Guide to the Tower of Babel, II. XX",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '3',
            quote: 'The Tower is not without its seasons of unrest, but so it is wherever humanity gathers. Dynasties rise and fall; oligarchs sweep up fortunes for revolutionaries to scatter. The wild swings of history have a way of sawing a society in half. Visitors are advised to be adaptable; when possible ride the saw, not the log.',
            source: "Everyman's Guide to the Tower of Babel, II. IX",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '4',
            quote: 'Never was a rough road smoothed by looking backward; never was a great height shrunk by looking down.',
            source: "Everyman's Guide to the Tower of Babel, I. XVI",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '5',
            quote: "Though Oyodin's architectural transparency has ushered in an era of relative peace, the war on smudges is unending. Be advised: While fingerprints are considered innocuous, the imprint of a posterior on the glass wall of a home or business is taken as a slight. Visitors should mind where they lean.",
            source: "Everyman's Guide to the Tower of Babel, VII. IV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '6',
            quote: 'The term marine snow is used to describe the drifting passage of rotting matter down through the unlit ocean depths to the seafloor. Similarly, the rain of grout and rubble one may encounter in the shadow of the Tower is known as a stone shower.',
            source: "Everyman's Guide to the Tower of Babel, VI. III",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: 'From the Belly of the Beast 2',
            quote: 'We plow the fields of history with pens and pots of candle black, for the fruits of tomorrow cannot grow until the stubble of the past is tilled under.',
            source: 'from the sermons of Luc Marat (translated from hoddish)',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '7',
            quote: 'Anyone who has ever peered into a telescope and spied the unexplored sprawl bottled therein knows that wonder only ceases when examination ends.',
            source: "Everyman's Guide to the Tower of Babel, VII. XII",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '8',
            quote: 'A lock tempts a pick. A chain invites a file. But a dung pile courts only beetles and flies. Sometimes the best way to protect your belongings is to shroud them in something foul.',
            source: "Everyman's Guide to the Tower of Babel, I. III",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '9',
            quote: 'Much as opportunity may turn the placid grasshopper into a locust swarm, panic may convert an idle crowd into a rampaging horde.',
            source: "Everyman's Guide to the Tower of Babel, I. II",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '10',
            quote: 'A half-hearted apology is as good as a half-struck match.',
            source: "Everyman's Guide to the Tower of Babel, III. I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '11',
            quote: 'Travelers who mistake inconvenience for catastrophe or confuse a detour for derailment only deplete their mettle. There is no need to embellish difficulty; hardship is quite content to embroider itself.',
            source: "Everyman's Guide to the Tower of Babel, II. V",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '12',
            quote: 'Virtue tires; evil does not.',
            source: 'Music for Falling Down Stairs by Jumet',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: 'From the Belly of the Beast 3',
            quote: 'We have risen from the chimneys of history—we the soot of empire, we the cinders of suffering. Yet the kings of the Tower would blame us for this great conflagration. They say we are the smoke that lit this fire; we are the ashes that set the world alight.',
            source: 'from the sermons of Luc Marat (translated from hoddish)',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '13',
            quote: 'Do not waste the limited resource of your patience on those who think misery a competition. Spendthrifts of self-pity are always miserly the moment empathy is due.',
            source: "Everyman's Guide to the Tower of Babel, VI. V",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '14',
            quote: 'A reputation ruined on the front page was never restored by a retraction on the last. The guillotine gives no refunds, and neither does the press.',
            source: "Everyman's Guide to the Tower of Babel, XII. I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '15',
            quote: "The holdings of a ringdom's Lost and Found are auctioned off according to their perishable nature: lost milk, bread, and cut flowers are sold after a day; lost fruit and leafy vegetables are sold after three days; lost onions, tubers, and children after a week; hard cheese and smoked meat go on the block after a fortnight.",
            source: "Everyman's Guide to the Tower of Babel, I. VII",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '16',
            quote: "Inside the walls of the monolith, black water is stored in immense stacks and under great pressure to keep it from draining into the already overtaxed aquifers below. The arrangement has led some to call the Tower Ur's largest unflushed toilet.",
            source: "Everyman's Guide to the Tower of Babel, VI. I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '17',
            quote: 'Be leery of the agreeable grocer who bags your plums behind the counter. Be suspicious of the obliging clothier who offers to wrap your shirts in the backroom. Be wary of helpful persons, lest you go home with a bag full of rot or a box full of rags.',
            source: "Everyman's Guide to the Tower of Babel, V. X",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '18',
            quote: 'Leapt fences have spoiled many trousers. Often it is while skirting inconvenience that we encounter catastrophe.',
            source: "Everyman's Guide to the Tower of Babel, II. I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '19',
            quote: 'An axe-head survives its handle, and a ship outlives many sails.',
            source: 'Music for Falling Down Stairs by Jumet',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '20',
            quote: 'True conspiracies are inflexible and susceptible to discovery, but imaginary plots are ever evolving and, as a result, invulnerable. That is to say, conspiracies are perishable, paranoia is not. ',
            source: "Everyman's Guide to the Tower of Babel, VIII. I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: 'From the Belly of the Beast 4',
            quote: 'Souls are like sails. Without a mast to cling to and a ship to pull, the ocean winds will whip them to shreds. It is constraint, purpose, and labor that gives a soul its shape. ',
            source: 'from the sermons of Luc Marat (translated from hoddish)',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '1',
            quote: 'A choice lightly made may change the course of a life. That is to say, visitors are advised against ordering shellfish from the cafés of Simbersae.',
            source: "Everyman's Guide to the Tower of Babel, VIII. II",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '2',
            quote: "It is true that what distinguishes the amateur from the professional is not a single stroke of success but rather the lengthy education of repeated failure. Even so, visitors are advised to beware the captains who boast about the many shipwrecks they've survived.",
            source: "Everyman's Guide to the Tower of Babel, II. VI",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '3',
            quote: 'The Shadowless Hour, when the sun shines directly above the Tower, is commemorated that night by a great dispersal of sky lanterns, the scattering of which briefly resembles the birth of a galaxy, an impression that is somewhat spoiled by the resulting widespread fires.',
            source: "Everyman's Guide to the Tower of Babel, VI. XII",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '4',
            quote: 'Not all political feuds are heated. The diplomatic bond between some factions is like the unhappily wedded pair who, having endured many decades of infraction and contention, no longer see the need to vocalize their revulsion. They can bicker well enough in silence.',
            source: "Everyman's Guide to the Tower of Babel, VI. VI",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '5',
            quote: 'Sometimes distraction is the best poultice for disillusionment.',
            source: "Everyman's Guide to the Tower of Babel, I. IX",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '6',
            quote: 'Ambivalence is the midwife of happiness. Do not make promises when a shrug will suffice.',
            source: "Everyman's Guide to the Tower of Babel, IV. XVI",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '7',
            quote: 'In a tower full of farewells, eloquent goodbyes are surpassingly rare. Many go unmarked amid a gradual parting of ways, like a receding hairline or a fading tattoo. Though, there are those grand bon voyages that fly forth like a knocked-loose tooth: abrupt, inarticulate, and aching.',
            source: "Everyman's Guide to the Tower of Babel, III. IX",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '8',
            quote: 'The mind is ingenious at finding itself expressed in secret everywhere. We detect human faces in wood grain, countenances in cloudbanks, and profiles in the moon. It is comforting to think our visage is so ubiquitous; but alas, we are only vain.',
            source: 'Homage, Vol. II by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '9',
            quote: 'Pilgrims are encouraged to remember that the principal consumer of sheep is not wolves but shepherds.',
            source: "Everyman's Guide to the Tower of Babel, IV. IX",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '10',
            quote: 'The most immense, rapacious waves come bannered by the bluest days.',
            source: 'Music for Falling Down Stairs by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '11',
            quote: 'Some fear the desolation of a broken heart; others dread the chronic suffering of an injured back. Both grievances benefit from a division of burdens. Lift your sorrows with a friendly ear and your luggage with a brawny porter.',
            source: "Everyman's Guide to the Tower of Babel, VII. I",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '12',
            quote: 'Outrage, like charity, is sweeter with an audience.',
            source: "Everyman's Guide to the Tower of Babel, IV. VIII",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '13',
            quote: 'Should you ever feel dread at the prospect of where your adventures have carried you, take solace in the fact that the vast majority of mortal accidents occur in the home. Perhaps the secret to longevity is absenteeism.',
            source: "Everyman's Guide to the Tower of Babel, IX. II",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '14',
            quote: 'Neglect never patched a crack, nor denial plugged a hole. Do not forget your imperfections; they have not forgotten you.',
            source: "Everyman's Guide to the Tower of Babel, II. VII",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '15',
            quote: 'The ground is full of self-righteous bones, but we would do well to remember it is the cause, not the loss, that ennobles sacrifice.',
            source: 'Homage, Vol. II by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '16',
            quote: "And then I dreamt the Tower bare and trembling to the root. As fall to winter's table bore a most peculiar fruit.",
            source: 'Music for Falling Down Stairs by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '17',
            quote: 'If mankind ever attempts to colonize the islands of the stars, we should crew the ships with children and put the youngest at the wheel.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '18',
            quote: 'Like a drowning soul floundering at sea, the hand of history reaches for objects of convenience rather than perfection. And so are heroes gripped.',
            source: 'Homage, Vol. III by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '19',
            quote: 'Do not race your postcards home. Dally long enough for word of your adventures to arrive before you. Let them announce you and lay the foundation for your legend.',
            source: "Everyman's Guide to the Tower of Babel, I. I",
        },
    ],
};
