import jss from 'jss';
import preset from 'jss-preset-default';
import jssPluginGlobal from 'jss-plugin-global';

jss.setup(preset());
jss.use(jssPluginGlobal());

const bookOneColor = '#a1272e';
const bookTwoColor = '#02A4F5';
const bookThreeColor = '#9024D2';
const bookFourColor = '#F29900';

export const styles = jss
    .createStyleSheet({
        '@global': {
            body: {
                height: '100%',
                width: '100%',
                margin: 0,
                padding: 0,
                fontFamily: 'helvetica, arial, sans-serif',
                color: '#222222',
            },
        },
        bookOne: {
            color: bookOneColor,
        },
        bookTwo: {
            color: bookTwoColor,
        },
        bookThree: {
            color: bookThreeColor,
        },
        bookFour: {
            color: bookFourColor,
        },
        bookOneWrapper: {
            padding: '1rem',
            background: bookOneColor,
        },
        bookTwoWrapper: {
            padding: '1rem',
            background: bookTwoColor,
        },
        bookThreeWrapper: {
            padding: '1rem',
            background: bookThreeColor,
        },
        bookFourWrapper: {
            padding: '1rem',
            backgroundColor: bookFourColor,
        },
        bookOneTwoTransition: {
            padding: '2rem',
            background: `linear-gradient(${bookOneColor}, ${bookTwoColor})`,
        },
        bookTwoThreeTransition: {
            padding: '2rem',
            background: `linear-gradient(${bookTwoColor}, ${bookThreeColor})`,
        },
        bookThreeFourTransition: {
            padding: '2rem',
            background: `linear-gradient(${bookThreeColor}, ${bookFourColor})`,
        },
        header: {
            height: '100vh',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            backgroundColor: 'lightgrey',
        },
        svg: {
            height: '100%',
            width: '100%',
        },
        title: {
            textAlign: 'center',
            fontSize: 'xx-large',
            fontWeight: 'bold',
        },
        part: {
            textAlign: 'center',
            fontSize: 'large',
            fontWeight: 'bold',
        },
        chapter: {
            padding: '1rem',
        },
        quoteContainer: {
            display: 'flex',
            alignItems: 'center',
        },
        chapterNumber: {
            fontSize: 'large',
            marginRight: '1rem',
        },
        quote: {
            display: 'block',
            fontSize: '1.2rem',
            position: 'relative',
            textAlign: 'justify',
        },
        source: {
            display: 'block',
            textAlign: 'right',
        },
    })
    .attach();
