const TITLE = 'Arm Of The Sphinx';

const PART1 = 'Part I - The Stone Cloud';
const PART2 = 'Part II - The Golden Zoo';
const PART3 = 'Part III - The Bottomless Library';

export const bookTwo = {
    title: TITLE,
    parts: [PART1, PART2, PART3],
    chapters: [
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '1',
            quote: 'The difficulty with a disguise is that it must be worn for some time before it hangs credibly upon the shoulders. But if worn for too long, a costume becomes comfortable, natural. A man always in disguise must take care lest he become the disguise.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '2',
            quote: "With his ship, I have also taken possession of the late Captain Billy Lee's log. Reading his diary has provided me with two insights. First, penmanship is not a priority in the elementary institutions of the Tower. And second, I have signed us on for a rough life.",
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '3',
            quote: 'Compared to how ably Adam and Voleta adapted to our new life, my own acclimation was painfully slow. If mankind ever attempts to colonize the islands of the stars, we should crew the ships with children and put the youngest at the wheel.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '4',
            quote: 'Trust is a muscle that works best in reflex.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '5',
            quote: "One can't turn around in a pirate's cove without being accosted by some charlatan selling the promise of treasure. Though I suppose it is comforting to think that there is a reliable wage to be made drawing and selling treasure maps. The same could be said of writing travel guides.",
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '6',
            quote: 'Omissions become lies, etc.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '7',
            quote: "I find myself in the unenviable position of having to rely upon another man's impression of my wife to correct my recollection of her. I don't know what I would do without Ogier's portrait of Marya to clear my muddled head.",
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '8',
            quote: 'I knew a boy in school who rolled out of his bunk in his sleep, struck his head, and never woke again. I console myself with this terrible memory whenever I look down at the chasm that follows me like a shadow. We are, all of us, living at a deadly height.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '9',
            quote: 'One need not be royalty to be in high demand. Even the plowman and the dairymaid are thought exotic in the ringdoms of Babel.',
            source: "The Everyman's Guide to the Tower of Babel, Vol. IV",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '10',
            quote: 'In the natural world there are two varieties of awe: the carnal awe associated with reproduction, and the hypnotic awe experienced by the prey of certain predators, such as the stoat. After years of observation, I am still not sure which variety of awe the Tower inspires.',
            source: 'Reflections on a Pillar, John Clark',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART1,
            chapter: '11',
            quote: 'The clan of the Pells is distinguished by their courtly manner. A Pell is always well dressed, sweetly perfumed, and arrayed in the most current fashions. They are harmless in general, but loquacious to a fault; if ever corned by a Pell, one is in reasonable danger of being charmed to death.',
            source: 'Anthropologies of Babel by A. Franboise',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '1',
            quote: 'We are, each of us, a multitude. I am not the man I was this morning, nor the man of yesterday. I am a throng of myself queued through time. We are, gentle reader, each a crowd within a crowd.',
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '2',
            quote: "Civilization first came into being when two of our ancestors knocked together at the mouth of a cave, and one brute or the other uttered the immortal phrase: 'No, no, I insist— after you.'",
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '3',
            quote: 'Gentle reader, do not make yourself an arbor under which other pedestrians must pass. Move your embraces and glad reunions to the shoulder. Even a great romance is but a stumbling block when it happens upon the road.',
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '4',
            quote: 'I admit, your lordship, to having had my doubts about boarding spiders and spider-eaters in a public park. It seemed to my admittedly meager imagination a bit like inviting rats into your home and then adopting tigers to keep them in check.',
            source: 'Inaugurations of the Silk Gardens, Salo',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '5',
            quote: "Should you ever be tempted to dip your toes in the Garden's aquifers, just remember where the water flows. One man's bath becomes another man's broth.",
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '6',
            quote: 'Without military experience to shape and temper them, young men turn into idlers, my lord; they turn into bards. The Gardens are full of them, lounging on elbows with their shirts open, spewing poetry at plain-faced girls. I shudder at the waste of it. We will owe this generation a war.',
            source: 'Inaugurations of the Silk Gardens, Salo',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '7',
            quote: 'When the gilded birds of the Zoo trilled their fantastic song, women swooned, fops moaned like doves, and old men mopped their rheumy eyes. This is the trouble with the man of the masses: show him the sublime, and he is reminded of himself.',
            source: 'Inaugurations of the Silk Gardens, Salo',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '8',
            quote: 'Please do not saddle the clockwork beasts. Do not pet or tease the spidereaters. Reserve such familiar activities for other, more receptive subjects.',
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '9',
            quote: "After the initial shock receded, I quickly forgot Edith's arm was at all unnatural. Much as spectacles flatter an intelligent face, the powerful engine compliments her quite well. It is difficult to fathom that such an essential and vital part of her could one day run down like a pocket watch.",
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '10',
            quote: 'The man or woman who is rarely lost, rarely discovers anything new.',
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '11',
            quote: 'If living on a coast taught me anything it was that every ship in the sea wants to sink. It is only the frenzy of the crew and the grit of the command that keeps a ship from foundering. The Stone Cloud is no different. If it soars, it is by dint of our will. It sinks as a matter of course.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '12',
            quote: "I'm suspicious of men who think it better to revise an entire society than to reform their own manner of address. A constructive revolution is as impossible as an architectural fire.",
            source: 'Inaugurations of the Silk Gardens, Salo',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '13',
            quote: 'Despite the evidence of my chosen profession, I am far from comfortable with violence. Unfortunately, the consequence of practice is mastery.',
            source: "The Stone Cloud's Logbook, Captain Tom Mudd",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART2,
            chapter: '14',
            quote: "There is little in the world more curative than a picnic. Some call for doctors and tonics when they fall ill. I call for friends and wine. 'But' you say, 'What if you are really dying?' Of course I am! We all are! The question is, gentle reader, in these uncertain times, would you rather be a patient or a picnicker?",
            source: 'Folkways and Right of Ways in the Silk Gardens, Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '1',
            quote: 'It is common knowledge the Sphinx does not exist. This fact, however, has not diminished his fame.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '2',
            quote: 'The origin of a myth is like that of a river. It begins in obscurity as a collection of tentative, unassociated flows. It streams downhill along the path of least resistance, seeking consensus. Other fables join it, and the myth broadens and sets. We build cities on the banks of myth.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '3',
            quote: "Had the Sphinx not existed, surely the mothers of the Tower would have had to invent him. Who better to encourage children in their studies than a ghoul who riddles you and eats you if you answer wrong? 'Why should you study, my son? Because you never know what the Sphinx might ask!'",
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '4',
            quote: 'The Sphinx could never reveal himself without losing his essential mystique. To be the Sphinx is to be unknown. If, however, he were a myth, he would be just as unknowable. We can only hope that one day he will emerge and prove once and for all that he does not exist.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '5',
            quote: 'Some scholars believe the Sphinx must be a supreme mesmerist to bring so many to ruin. He spellbinds his victims into self-destruction. Other students of the Sphinx, however, contend that, rather than hypnosis, he practices the black art of legal contracts.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '6',
            quote: 'It is by studying the Sphinx that we realize all wonder is seasoned with dread; all courage is tinctured with fear; all wisdom is the fermentation of folly.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '7',
            quote: 'Routine is rather like the egg-whites in a batter: it imparts little flavor, but it holds everything together.',
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '8',
            quote: 'A short list of potent stuffs for sparing use: cloves, especially in mulled wine, but also in baked goods; perfume, which should be undetectable to you and elusive to everyone else; and frank conversation in every instance.',
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '9',
            quote: 'Take stock of your virtues. Aspire within your means. No one admires the bird who sings over the orchestra.',
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '10',
            quote: "Outings are essential to the health of a wife's self-esteem. How else can she learn if her things are in fashion, or if she has become the subject of gossip? As a rule, never air your linens more often than yourself.",
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '11',
            quote: "When introductions are made, never be first in the reception line. 'First to curtsey, first forgot,' or so the saying goes.",
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '12',
            quote: 'C is for Cheeky, Coquettish, and Coy, such as the girl who treats hearts as a toy.',
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '13',
            quote: "G is for grumble, groan and for grouch: such as the one who is quick to cry, 'Ouch!'",
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '14',
            quote: 'L is for liar, lip-server, and lout; such as the boy who has more than one mouth.',
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '15',
            quote: 'S is for snooper, snake, and for sneak, such as the boy who takes just a peek.',
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '16',
            quote: 'F is for fawning, false, and for fake, who lives to be liked by those that he hates.',
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '17',
            quote: 'P is for prankster, picador, and peeve, or the young lad who keeps tricks up his sleeve.',
            source: 'The Unlikable Alphabet, a Primer for Children by Anon.',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '18',
            quote: 'All glory goes to the man who is willing to hurl himself, hat and boot, over the brink just to hear in his dramatic exit a smattering of applause.',
            source: "A Beginner's Guide to the Game of Oops",
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '19',
            quote: "A bad painter only worries about how his barn looks. He doesn't work his brush into the nooks and shadows. His barn is handsome enough from the road. But when a wet spring comes, the eaves fall off. Do not neglect inconvenient corners.",
            source: 'The Art of Painting a Barn by Mr. B. Ritter',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '20',
            quote: "The lion's share of blunders occur in the final hour of a job. Pails are kicked, hinges painted over, and brushes lost in the lime. When the end is in sight, mind how you go.",
            source: 'The Art of Painting a Barn by Mr. B. Ritter',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '21',
            quote: 'The essential lesson of the zoetrope is this: movement, indeed all progress, even the passage of time, is an illusion. Life is the repetition of stillness.',
            source: 'Zoetropes and Magic Lanterns: An Introduction to Moving Stills',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '22',
            quote: 'Do not allow small people to make large impressions. Do not fritter your beauty upon mirrors. Do not make wishes, for wishes only curse the life you have. Never forget, you stand at the end of a long line of short lives.',
            source: 'The Wifely Way by the Duchess K. A. Pell',
        },
        {
            book: 'Arm Of The Sphinx',
            part: PART3,
            chapter: '23',
            quote: 'Myth is the story of what we do not understand in ourselves.',
            source: 'The Myth of the Sphinx: A Historical Analysis by Saavedra',
        },
    ],
};
