const TITLE = 'Senlin Ascends';

const PART1 = 'Part I - The Basement & The Parlor';
const PART2 = 'Part II - The Baths';
const PART3 = 'Part III - New Babel';

export const bookOne = {
    title: TITLE,
    parts: [PART1, PART2, PART3],
    chapters: [
        {
            book: TITLE,
            part: PART1,
            chapter: '1',
            quote: "The Tower of Babel is most famous for the silk fineries and marvelous airships it produces, but visitors will discover other intangible exports. Whimsy, adventure, and romance are the Tower's real trade.",
            source: "Everyman's Guide to the Tower of Babel, I.V",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '2',
            quote: "Savvy shoppers will revel in the Market that coils about the foot of the Tower. Don't be afraid to walk away while haggling; a little retreat may win a great bargain.",
            source: "Everyman's Guide to the Tower of Babel, I.IV",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '3',
            quote: 'The happy traveler will look for the broadest, most beaten path, will look to his fellow traveler for behavioral cues, will be an echo but will not raise his voice. It is dangerous to blaze a trail when one is already so clearly cut.',
            source: "Everyman's Guide to the Tower of Babel, I.VI",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '4',
            quote: 'The camaraderie between travelers becomes more palpable the closer one draws to the Tower. Do not be surprised if you find yourself swept up in a spontaneous parade.',
            source: "Everyman's Guide to the Tower of Babel, II.XIV",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '5',
            quote: "The Tower's well produces a water that is famously crisp and pure. It is this untainted source which gives the local beer its ballyhooed flavor.",
            source: "Everyman's Guide to the Tower of Babel, III.II",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '6',
            quote: 'The handkerchief is the universal utensil of the seasoned traveler. It can be a sanitizing device, a seat cover, a dust mask, a garrote, a bandage, a gag, or a white flag. One may feel well-prepared with nothing but a pocket square.',
            source: "Everyman's Guide to the Tower of Babel, Appendix, I.IV",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '7',
            quote: 'Newcomers may expect the ringdoms of the Tower to be like the layers of a cake where each layer is much like the last. But this is not the case. Not at all. Each ringdom is unique and bewildering. The ringdoms of the Tower share only two things in common: the shape of their outermost walls, which are roughly circular, and the price of beef, which is outrageous. The rest is novel.',
            source: "Everyman's Guide to the Tower of Babel, I.X",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '8',
            quote: 'Never let a rigid itinerary discourage you from an unexpected adventure.',
            source: "Everyman's Guide to the Tower of Babel, III.II",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '9',
            quote: 'Inevitably, invariably, eventually you will discover you are unprepared to make an informed choice. When in doubt, say, Yes. Yes is the eternal passport. Yes is the everlasting coin.',
            source: "Everyman's Guide to the Tower of Babel, I.XII",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '10',
            quote: 'Anything that distracts from the play becomes the play itself.',
            source: "Everyman's Guide to the Tower of Babel, III.V",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '11',
            quote: 'If the actors are any good, or the script is, or the director, then the audience will be as quiet as a sigh. Unless, of course, the play is a comedy. Then quiet is a terrible and tormenting thing.',
            source: "Everyman's Guide to the Tower of Babel, III.XI",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '12',
            quote: 'Bribery wins more arguments than reason.',
            source: "Everyman's Guide to the Tower of Babel, I.IX",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '13',
            quote: "Ask anyone you meet, Don't you miss the sun? Don't you miss the moon? They'll reply, Do you miss the heatstroke? Do you miss the howling wolves?",
            source: "Everyman's Guide to the Tower of Babel, III.XII",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '14',
            quote: 'If you ever discover that you are bored in the Parlor, wake up. You are asleep and having a tedious dream.',
            source: "Everyman's Guide to the Tower of Babel, III.LI",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '15',
            quote: 'Most life in the ocean lives in the shallows. And so it is with those living on the land.',
            source: "Everyman's Guide to the Tower of Babel, I.IIV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '1',
            quote: "The Fountain of the Baths can unwrinkle a crone's elbow, can mend sprained muscles and strained hearts alike, can dislodge the most stubborn tic. Whatever is worrisome will be forgot.",
            source: "Everyman's Guide to the Tower of Babel, IV.III",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '2',
            quote: 'The only real danger is of growing so relaxed that one falls asleep while soaking in a bath. To prevent accidental drowning, go with a companion or seek out a new friend.',
            source: "Everyman's Guide to the Tower of Babel, IV.IV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '3',
            quote: 'Even beauty diminishes with study. It is better to glance than gawk.',
            source: "Everyman's Guide to the Tower of Babel, IV.V",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '4',
            quote: 'Conversations are a tedious symptom of an empty dance card.',
            source: "Everyman's Guide to the Tower of Babel, IV.VII",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '5',
            quote: 'There are endless currencies beyond the bills and coins in your pocket. Sometimes a ticket may be bought with a smile; a glass of wine may be payment enough for an entertaining tale.',
            source: "Everyman's Guide to the Tower of Babel, IV.XI",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '6',
            quote: "Tourists who talk too often and too fondly of their homes can expect a lukewarm reception. Locals call such nostalgic tourists 'dirt-headed' or 'mud-minded.' One can hardly blame them. 'Home' is an exaggeration made true by distance.",
            source: "Everyman's Guide to the Tower of Babel, IV.XII",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '7',
            quote: 'The politics of the Tower are like garden politics, like neighbors bickering over the ownership of a plum tree. You may detect undercurrents of rivalry and feud, though none are very serious. Even so, it is best to have a supple opinion in matters of local governance.',
            source: "Everyman's Guide to the Tower of Babel, I.XIV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '8',
            quote: 'Often the simplest way to unlock a door is to knock upon it.',
            source: "Everyman's Guide to the Tower of Babel, IV.I",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '9',
            quote: 'The longer you linger in the halls of Babel, the more strongly you will feel the pull of allegiances, of clans, kings, and guilds. A man who stands alone is generally thought a lost tourist or a rogue. Many have found the one is a natural sibling of the other.',
            source: "Everyman's Guide to the Tower of Babel, IV.XX",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '10',
            quote: 'The Baths are like a chrysalis. Exhausted men and women wrap the Baths about them and, in a fortnight, are transformed. One may come in a worm, and go out a butterfly.',
            source: "Everyman's Guide to the Tower of Babel, V.III",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '11',
            quote: 'The truth, when finally told, will often sound strange, while a lie is so often familiar.',
            source: "Everyman's Guide to the Tower of Babel, V.IV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '12',
            quote: "One shouldn't feel compelled to attend every ball, or accept every proposal, or finish every glass that is raised. The sun is sometimes brighter when watched from the shadows. Sometimes to enjoy a scene fully, we must first retreat a little way.",
            source: "Everyman's Guide to the Tower of Babel, V.XIV",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '13',
            quote: "The trade winds climb the Tower along a spiraling, tangled course. Ships do not rise up and down the Tower like plumbs on a line, but rather twist their way up like ivy climbing a tree. 'Up' is not at all a straightforward direction.",
            source: "Everyman's Guide to the Tower of Babel, I.XIII",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '1',
            quote: 'Volume II in the Everyman series describes the many marvels of New Babel: the Lightning Nest, the Chrom chapels, its population of exotic moths and bats, and how it earned the titillating nickname the Bedroom. Request a copy from your local bookman today!',
            source: "Everyman's Guide to the Tower of Babel, V.XXII",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '2',
            quote: 'The simplest way to make the world mysterious and terrifying to a man is to chase him through it.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '3',
            quote: 'There is a narcotic, unique to the Tower and in particular New Babel, called White Chrom or Crumb, among other things. The porters call it Crumb because it makes the real world seem like the sort of thing a mouse could eat in one bite and still be hungry.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '4',
            quote: "Goll's Port is not an original feature of the Tower. It was dug out eons after the Tower's erection, and the fact shows in its shoddy form. It has been renamed throughout the centuries by a parade of ambitious men, and yet appears to have stubbornly remained little better, little nobler than a smuggler's cove.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '5',
            quote: 'An ancient and beloved teacher once told me that a journal is the only book a man can undertake and know for certain he will one day finish.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '6',
            quote: 'Presuming that I can obtain a ship, I wonder how I would crew it. Of course, I cannot afford to hire airmen, nor can I tolerate the pirate option of violent salaries. No, each one of my crew must come on their own, for their own reasons.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '7',
            quote: 'Even with a crew and a ship, escaping the port requires the right wind. A single airstream feeds the entire Port of Goll; all ships come in from the low south and depart to the high north. When there is only one road out of town, runaways are easy to catch.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '8',
            quote: "Today's candidate: the Fat Alistair. She's a merchant ship, forty-six feet, stem to stern, with two twenty-pound guns and bunks for twelve. A good candidate on the surface of it; unfortunately, she flies the colors of Pelphia. Stealing from the Pells, who I must one day infiltrate, seems beyond stupid. The search continues.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '9',
            quote: "Tucked among the old ledgers on my office shelves are a dozen flawed dictionaries, several primers on aeronautics (which I have thoroughly perused and the best of which I've claimed as my own), and at least thirty unique and useless guides to the Tower. When I read them, I want to shout, Draw me a map! Show me the way! But all the authors do is describe their footprints and talk about their shoes, which are always the best, the only true shoes.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '10',
            quote: "The Banyan o' Morrow is a flat-bottomed scow that's as ugly as a pig's nose. One thirty-pound gun, heavily corroded, is its only defense. The motley crew of six would probably surrender without argument. A charmless but feasible candidate. (On second inspection, rot has turned the bulwarks soft as cake. It is a deathtrap.)",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '11',
            quote: 'The Double Fond is a pot-bellied galleon, with an s-shaped hull, eight long guns, three fat envelopes, a grappling cannon, a glorious deckhouse, a great cabin complete with teardrop chandelier, and a crew of sixty-two armed marauders… All dreaming aside, a ship is only as good as her course. I must find a new wind.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '12',
            quote: "Everything I've read on the subject suggests that five able bodies are required to make a skeletal crew. Counting myself, Adam, and Voleta, and presuming that I can recruit Iren, I'm still one short. I suppose I could advertise in the dockyard. Aeronaut wanted for crusade into certain peril and probable death. Low wages, moral reward; philanthropists preferred.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '13',
            quote: 'Mirrors are not so honest as one might think. They can be mugged at, bargained with, and one can always ferret out a flattering angle. Really, there is nothing like the expression of a long-lost friend to reflect the honest state of your affairs.',
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '14',
            quote: "Every important journey I have undertaken has begun the same: with crushed sheets, a balled pillow, flung open books, and not a wink of sleep. Tonight, I added a new sort of frittering to the ritual: sewing. I have at least solved the question of where to hide the painting, though I mangled my coat's lining in the process.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '15',
            quote: "I can't stop thinking about that cocky, yellow-haired woman who tried to blackmail me. She must've felt so very clever. She really believed she'd make a fortune off of me and redeem her life. It wasn't a bad plan. But she was doomed by insignificance and ignorance and hope. And it may be no different for me.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '16',
            quote: "When teaching me to load a gun, Adam warned that an overloaded barrel does not fire faster or farther, it merely explodes in one's face. So it may be in the port this evening when Rodion and Finn Goll collide.",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '17',
            quote: "I still recall a line from that feckless Everyman's guide. It said something like, 'the Tower's real trade is in whimsy, adventure, and romance.' I cannot imagine a less accurate trio. Though, who in their right minds would've come if the editors had said, 'the Tower's true trade is in tyranny, dismemberment, and heartbreak?",
            source: "Every Man's Tower, One Man's Travails by T. Senlin",
        },
    ],
};
