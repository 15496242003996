import {bookOne} from '../data/bookOne';
import {bookTwo} from '../data/bookTwo';
import {bookThree} from '../data/bookThree';
import {bookFour} from '../data/bookFour';
import {Chapter} from './chapter';
import {Title} from './title';
import {Part} from './part';
import {styles} from '../styles';
import {Header} from './header';

export function App() {
    return (
        <>
            <Header />
            <div class={styles.classes.bookOneWrapper}>
                <Title className={styles.classes.bookOne} title={bookOne.title} />
                {bookOne.parts.map((part) => (
                    <>
                        <Part part={part} />
                        {bookOne.chapters
                            .filter((chapter) => chapter.part === part)
                            .map((chapter) => (
                                <Chapter chapter={chapter} />
                            ))}
                    </>
                ))}
            </div>
            <div class={styles.classes.bookOneTwoTransition} />
            <div class={styles.classes.bookTwoWrapper}>
                <Title className={styles.classes.bookTwo} title={bookTwo.title} />
                {bookTwo.parts.map((part) => (
                    <>
                        <Part part={part} />
                        {bookTwo.chapters
                            .filter((chapter) => chapter.part === part)
                            .map((chapter) => (
                                <Chapter chapter={chapter} />
                            ))}
                    </>
                ))}
            </div>
            <div class={styles.classes.bookTwoThreeTransition} />
            <div class={styles.classes.bookThreeWrapper}>
                <Title className={styles.classes.bookThree} title={bookThree.title} />
                {bookThree.parts.map((part) => (
                    <>
                        <Part part={part} />
                        {bookThree.chapters
                            .filter((chapter) => chapter.part === part)
                            .map((chapter) => (
                                <Chapter chapter={chapter} />
                            ))}
                    </>
                ))}
            </div>
            <div class={styles.classes.bookThreeFourTransition} />
            <div class={styles.classes.bookFourWrapper}>
                <Title className={styles.classes.bookFour} title={bookFour.title} />
                {bookFour.parts.map((part) => (
                    <>
                        <Part part={part} />
                        {bookFour.chapters
                            .filter((chapter) => chapter.part === part)
                            .map((chapter) => (
                                <Chapter chapter={chapter} />
                            ))}
                    </>
                ))}
            </div>
        </>
    );
}
