import {styles} from '../styles';

export function Chapter(props) {
    const {
        chapter: {chapter, quote, source},
    } = props;

    return (
        <div class={styles.classes.chapter}>
            <div class={styles.classes.quoteContainer}>
                <strong class={styles.classes.chapterNumber}>{chapter}</strong>
                <q class={styles.classes.quote}>{quote}</q>
            </div>
            <cite class={styles.classes.source}>{`– ${source}`}</cite>
        </div>
    );
}
