const TITLE = 'The Hod King';

const PART1 = 'Part I - The Mermaid';
const PART2 = 'Part II - The Leaping Lady';
const PART3 = 'Part III - The Gold Watch';

export const bookThree = {
    title: TITLE,
    parts: [PART1, PART2, PART3],
    chapters: [
        {
            book: TITLE,
            part: PART1,
            chapter: '1',
            quote: 'Some men seem to think that temperance is preservative, that moderation somehow pickles the soul. They would place their beating hearts inside jam jars if they could. Which does beg the question, what on earth are they saving themselves for?',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '2',
            quote: "Wallflowers are harmless. They are pretty things that stand in corners with a pleasant look on their face. It's the wall-weeds I can't stand. They moan about the mantles, mope upon the sofas, and pout about the punch bowls, waiting to be asked, Are you all right? You look so sad. Wall-weeds will linger for hours if you let them. And the only thing that seems to drive them away is other people's happiness.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '3',
            quote: "The Tower is a pestle grinding upon the mortar of the earth. It pulverizes bones, fortunes, kings, love, youth, and beauty. That is its purpose—to crush. So, no, I will not retract my one-star review of Café Sotto's shortbread. I'm sorry the baker is despondent to the point of suicide, but at least he knows now how I felt after eating his wretched biscuits.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '4',
            quote: "Originally, the parrots of Pelphia served as town criers. They raised the alarm when fires, rogues, or raiders threatened the peace. But more recently, a neurosis has gripped the flock: The birds have become insatiable gossips. Do not be surprised if a parrot divulges a neighbor's secret or your own.",
            source: "Everyman's Guide to the Tower of Babel, VII. III image",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '5',
            quote: "Oh, how airmen love to say our stars are wrong! They call Nature the supreme artist, apparently forgetting that Nature also paints our deck chairs with bird droppings and our backs with hairy moles. Pelphia's constellations were designed by a panel of famous artists and installed by master plumbers, not plopped willy-nilly about the sky by drunken Nature. Really, who's to say it's our stars that are wrong?",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '6',
            quote: 'There really is no point to teasing a Boskop. They are insensible to wit. One might as well whistle for a footstool or attempt to romance a mop.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '7',
            quote: "Approach an officer of the law as you might a stray dog in the street: Use dulcet tones; keep your hands out of your pockets; and don't look them too long in the eye.",
            source: "Everyman's Guide to the Tower of Babel, IV. II image",
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '8',
            quote: "In the span of just a few years, the Coterie have gone from being a drunken fraternity to the most influential society in the realm. I've had an easier time getting an audience with the king than an open seat at the Coterie Club.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '9',
            quote: "Don't saw off your arm to feed a dog. You only have two arms, and the world is full of dogs.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '10',
            quote: 'Why do we call a dishonest person two-faced? Is it really so honest to wear the same face day in, day out, regardless of our mood, our condition, or the event? We are not clocks! Have a face for every occasion, I say! Be honest: Wear a mask.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '11',
            quote: 'Eventually, the certainty of a noose is preferable to the agony of an appeal.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '12',
            quote: 'To whiten the complexion, one may drain a little blood. And the same is true of our pale city.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '13',
            quote: 'I never respond to invitations. It just smacks of desperation. The only event I am certain to attend is my funeral, and I hope to arrive very, very late.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART1,
            chapter: '14',
            quote: 'Ecstasy lies in that brief silence at the end of a play, when the performance is over but the applause has not yet begun.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '1',
            quote: "If you want to read her future, don't peer at a young lady's tea leaves or probe the lumps on her head. No, look to her table manners. I can observe a girl eat a fig, and afterward, tell you whether she will grow up to be a marchioness or a mudlark.",
            source: "Lady Graverly's Table: Rare Graces and Common Shames",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '2',
            quote: 'My grandfather, the royal magistrate, famously said: Sometimes a prisoner would rather stare at a bare wall than a barred window. Or, put another way, ladies: Do not taste the cake your figure cannot afford.',
            source: "Lady Graverly's Table: Rare Graces and Common Shames",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '3',
            quote: "At the round table of color, orange sits supreme. Orange is sublime. Orange is ablaze. And seated across from Lady Orange, we have Sir Purple. I ask you, is any color more vulgar? The word alone emerges like something from a lavatory. Purple. Plopple. It's all prunes, liver spots, and ink stains. If I ever utter a word of praise for that wretched hue, please snatch my pen away and gore me with it.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '4',
            quote: 'A screaming mob and a cheering public sound an awful lot alike.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '5',
            quote: 'No. 38: Because you have purchased a new frock and wish to air it before the fashion spoils. No. 39: Because you are intoxicated and in need of an audience.',
            source: '101 Reasons to Attend My Party by Lady Sandbom',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '6',
            quote: "No. 81: Because youth is fleeting and cannot be savored in retrospect. No. 82: Because your enemy has RSVP'd, and you wish to make a scene.",
            source: '101 Reasons to Attend My Party by Lady Sandbom',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '7',
            quote: 'Smiles are like candlelight. They can warm and cheer the bleakest room. But we would be wise not to forget: Even the brightest candle hides a blackened wick.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '8',
            quote: "I'd rather be misquoted than never quoted. Some of my best lines are missources.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '9',
            quote: 'I have seen men pierced by a wink and women gored by the cut of an eye. The gaze is a martial art.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '10',
            quote: "A lady's figure is her ledger. Aging is forgivable. Fattening is not.",
            source: "Lady Graverly's Table: Rare Graces and Common Shames",
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '11',
            quote: "There is little less charming than a spurned woman. I'd rather be cornered by a house fire than a heartbroken girl.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '12',
            quote: 'Two roads run to fame: one from fortune; the other, folly.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART2,
            chapter: '13',
            quote: 'Only people who go to bed early believe in happy endings. We night owls understand that happiness does not dwell in finales. It resides in anticipation, in revelry, and in worn-out welcomes. Endings are always sad.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '1',
            quote: 'Sadly, the secret to longevity may very well be mediocrity. We see evidence of this in the history of the menu of Café Tertre. The dreadfully inventive specials—the slink pie and lemon soup were seen once and never heard from again. The perfectly succulent ambrosia krill, which lit up its bisque with an ethereal light, was so popular we relished the crustacean into extinction. But the merely palatable, unremarkable pigeon pie will linger on the board forever.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '2',
            quote: 'An unexpected knock on my apartment door is as welcome as the drums of an invading army.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '3',
            quote: 'The rich learn lessons. The poor commit crimes. Mistakes are generally considered a mark of the middle class.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '4',
            quote: "I distrust sweet cocktails and cheerful men for the same reason: It's hard to tell how dangerous they are until they've knocked you on your ear.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '5',
            quote: 'A stain is only a stain if anyone notices it. Slosh wine onto the carpet? Scoot a sofa over the spot. Spill gravy on your shirt? Fan your ascot to cover it. Spoil your political reputation with a grievous indiscretion? Start a domestic purge or a foreign war.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '6',
            quote: "I love a good scandal. There's nothing more comforting than tut-tutting the public sins of another from the privacy of your own squalor.",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '7',
            quote: "I don't understand the appeal of those hateful screw-booths. I've heard some claim to have seen visions of lost loved ones, and others say they saw their true and shining selves buried beneath age or ugliness, but I've only ever been lampooned by the Willo'-the-Wisps. All they've ever taught me is that my inner nemesis is a crueler critic than my gravest enemy. So what? I still have to sleep with the fellow!",
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '8',
            quote: 'Whenever I stand on a rooftop and look down at the people in the streets, the world makes perfect sense.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '9',
            quote: 'An earnest failure is superior to immaculate potential.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '10',
            quote: 'If you lie loud and long enough, it eventually becomes the truth.',
            source: 'Oren Robinson of the Daily Reverie',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '11',
            quote: 'The mob does two things well: nothing and revolution.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '12',
            quote: 'All the birds in the forest sing when a lonesome rifle rings.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '13',
            quote: 'A man may rot like an egg: His shell does not show it, but all that is within him has gone foul.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '14',
            quote: 'Sometimes a wheel squeaks not because it is faulty but because it bears the most weight.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '15',
            quote: 'A man who is not suspicious of a philosophy that appeals to his nature is like the bull comforted by the rutted path that leads to the slaughterhouse.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '16',
            quote: 'The universe breathes in ragged breaths. The body dies. The fungus grows. The loam spreads. The tree roots. The forest burns. The cloud bursts. The flood drowns. The alluvium feeds the fields. In, out. In, out. There is no stasis, no stillness. The source of all misery lies in our insistence that tomorrow be like today. But if it were, if it ever were, it would spell the end of everything.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '17',
            quote: 'Some men spend their days pretending to be distinct, assured, or enigmatic. But in death, they are all as guileless as infants. In death, we are ourselves at last.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
        {
            book: TITLE,
            part: PART3,
            chapter: '18',
            quote: 'Memory is not like a box of stationery—easy to browse, reorder, and read. No, memories accumulate like leaves upon the forest floor. They are irregular and fragile. They crumble and break upon inspection. They turn to soil the deeper you go.',
            source: 'I Sip a Cup of Wind by Jumet',
        },
    ],
};
