import {styles} from '../styles';

export function Header() {
    return (
        <div class={styles.classes.header}>
            <svg class={styles.classes.svg} width='200' height='50' viewBox='0 0 200 50'>
                <text x='50%' y='50%' dominant-baseline='middle' text-anchor='middle' fill='#222222'>
                    The Books of Babel
                </text>
                <text x='50%' y='75%' dominant-baseline='middle' text-anchor='middle' font-size='6px' fill='#222222'>
                    Josiah Bancroft
                </text>
            </svg>
        </div>
    );
}
